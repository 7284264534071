// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//* Variable Declaration
const region = 'us-gov-west-1';
const apiUrl = 'https://hhvh6471hb.execute-api.us-gov-west-1.amazonaws.com/prod';
const state = 'az';
const city = 'payson';
const agencytype = 'court';
const envName = 'prod';

export const environment = {
  production: true,
  apiUrl: apiUrl,
  awsConf: {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-gov-west-1:d031a45a-bb34-4e28-b664-d972d66ad95e',

      // REQUIRED - Amazon Cognito Region
      region: region,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-gov-west-1_9O7pVH9DR',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '50nr0492uvg0k63dlutnluvl91',
    },
    Storage: {
      bucket: `${state}-${city}-${agencytype}-${envName}-ewarrant-uploads`,
      region: region,
    },
    API: {
      endpoints: [
        {
          name: 'cwApi',
          endpoint: apiUrl,
          region: region,
        },
      ],
    },
  },
  // can be ERROR | WARN | INFO | DEBUG | VERBOSE
  awsLogLevel: 'ERROR',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
